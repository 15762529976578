import React from "react";
import { Header } from "../components/Header/header";

const Contact=()=>{
    return(
        <div>
        <Header/>
        </div>
    );
}
export {Contact};