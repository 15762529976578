import react, { Fragment } from "react";
import './header.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar,Container,Nav } from "react-bootstrap";
import { Link } from "react-router-dom";


const Header=()=>{
    return(
        <Fragment>
            <Navbar style={{backgroundColor:'#ffffff'}}>
                <Container >
                {/* <Navbar.Brand href="#home">Deepson Shrestha</Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-end" style={{ width: "100%" }}>
                        <Nav.Link className='navItem'  as={Link} to="/">Home</Nav.Link>
                        <Nav.Link href='https://blog.deepsonshrestha.com.np' target="_blank" className='navItem'>Blog</Nav.Link>
                        <Nav.Link className='navItem' as={Link} to ="/projects">Projects</Nav.Link>
                        <Nav.Link href='mailto:deepsonshrestha@yahoo.com' className='navItem'>Contact Me</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    );
}


export {Header};