import React, { Fragment,Link } from "react";
import './proj.css';
import { Row,Col, Container } from "react-bootstrap";

const Proj=()=>{
    return(
        <Fragment>
            <Container>
                <Row>
                    <div className='col-lg-12'  style={{paddingBottom:'20px'}}>
                        <h2>
                            {/* Completed Projects  */}
                        </h2>
                    </div>
                </Row>
                <Row>
                    
                    <Col className='proj-box'>
                    <a href='https://www.shfurniture.com.np/' target='_blank'>
                        <div className='left'>
                            <h3>
                            <span className='onlyHead'>
                                S.H. Furniture
                            </span>
                        </h3>
                        
                            React<br/>
                            Django REST<br/>
                            PostgreSQL<br/>
                            AWS<br/>
                            Google Cloud<br/>
                        </div>
                        </a>
                    </Col>
                    
                    <Col className='proj-box'>
                        <a href="https://colab.research.google.com/drive/1egGpcjBM2qN1wtlprdO1LwssEFZBGz8x?usp=sharing" target='_blank'>
                        <div className='right'> 
                        <h3>
                            COVID 19 World Analysis
                        </h3>

                            Pandas<br/>
                            Numpy<br/>
                            Seaborn<br/>
                            Google Colab<br/>
                            </div>
                        </a>
                    </Col>
                </Row>

                <Row>
                    <Col className='proj-box'>
                        <a href="https://www.krishnahotel.com.np/" target="_blank">
                        <div className='left'>
                            <h3>
                        Hotel Krishna
                        </h3>
                        
                            Django<br/>
                            Bootstrap<br/>
                            Vanilla JS<br/>
                            Jinja<br/>
                            ORM<br/>
                            PostgreSQL<br/>
                            Google Cloud<br/>
                      </div>
                      </a>
                    </Col>
                    <Col className='proj-box'>
                        <a href='https://taktundhis.pythonanywhere.com/'target='_blank'>
                        <div className='right'>
                            <h3>
                            Video Streaming (Chillax)
                        </h3>
                        
                            Django<br/>
                            Jinja<br/>
                            Sqlite<br/>
                            ORM<br/>
                            Pythonanywhere<br/>
                        </div>
                        </a>
                    </Col>
                </Row>

                <Row>
                    <Col className='proj-box'>
                        <a href='https://spam-classifier-deepson.herokuapp.com/' target='_blank'>
                        <div className='left'>
                            <h3>
                        SPAM HAM Classifier
                        </h3>
                        
                            Sklearn (Naive Bayes)<br/>
                            Pandas<br/>
                            Numpy<br/>
                            Django<br/>
                            Pythonanywhere<br/>
                        </div>
                        </a>
                        
                    </Col>
                    <Col className='proj-box'>
                        <a href='https://textdeepson.pythonanywhere.com/' target='_blank'>
                        <div className='right'>
                            <h3>
                            Text Deepson
                            </h3>
                            
                                Twillio SMS API<br/>
                                Django<br/>
                        </div>
                        </a>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export {Proj};