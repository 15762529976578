import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header/header';
import { Hero } from './components/Hero/hero';
import { Tech } from './components/Tech/tech';
import { Footer } from './components/Footer/footer';

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/> 
      <Tech/>
      <Footer/>
    </div>
  );
}

export default App;
