import React, { Fragment } from "react";
import './hero.css';
import { Col, Container, Row,Button } from "react-bootstrap";
import cover from '../../assets/images/cover.png';
import hello from '../../assets/images/hello.svg';
// import dev from '../../assets/images/dev.svg';

const Hero =()=>{
    return(
        <Fragment>
            <Container  className='hero'>
                <Row>
                    <div className='col-lg-8' id='hello'>
                            <h3 style={{color:'black'}}>Hello</h3>
                        <span id='deeps'>I'm <span style={{backgroundColor:'#6ead3a',color:'#ffffff'}}>Deepson Shrestha</span></span><br/>
                        <div className='cont'>
                        Technophile<br/>
                        Data Analytics Engineer<br/>
                        Backend Developer<br/>
                        </div>
                        <div className='cont'>
                        <a href='https://drive.google.com/file/d/1TF-fCqYjprlVfrLUGIBkNYERyKVOIADr/view?usp=sharing' target='_blank'><Button variant="outline-success" id='download'>Download CV</Button></a>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                            <img src='image/dev.svg'/>

                    </div>
                </Row>
            </Container>
        </Fragment>
    );
}

export {Hero};
