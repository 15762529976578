import React, { Fragment } from "react";
import './project.css';
import {Header} from '../components/Header/header';
import {Footer} from '../components/Footer/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar,Container,Nav } from "react-bootstrap";
import { Tech } from "../components/Tech/tech";
import { Proj } from "../components/Proj/proj";

const Project=()=>{
    return(
        <div className='Project'>
            <Header/>
            <Proj/>
            <Footer/>
        </div>
    );
}

export {Project};

