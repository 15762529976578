import React, { Fragment } from "react";
import './footer.css';
import { Container,Row } from "react-bootstrap";

const Footer=()=>{
    return(
        <Fragment className='footer'>
            <Container fluid id='foot'>
                <Row>
            <div className='col-md-12'>
                &copy; Deepson Shrestha | 2024
            </div>
            </Row>
            </Container>
        </Fragment>
    );
}

export {Footer};