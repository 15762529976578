import React, { Fragment } from "react";
import { Col, Container,Row } from "react-bootstrap";
import './tech.css';
import ai from'../../assets/images/ai.svg';
import analysis from '../../assets/images/analysis.svg';
import analysis2 from '../../assets/images/analysis2.svg';
import rest from '../../assets/images/rest.svg';
import fullstack from '../../assets/images/fullstack.svg';


const Tech=()=>{
    return(
        <div id='main'>
            <Container className='me-auto'>
                <Row>
                <Col>
                    <h1>Experience On</h1>
                </Col>
                </Row>
                <Row>
                    <Col className='tech-box' >
                        <img src={ai}/><br/>
                        Training and Deploying ML models
                    </Col>
                    
                    <Col className='tech-box'>
                        <img src={analysis2}/><br/>
                        Mining insights from Raw data
                    </Col>
                   
                    <Col className='tech-box'>
                        <img src={rest}/><br/>
                        Desiging and Implementing RESTful Architecture
                    </Col>
                  
                    <Col className='tech-box'>
                        <img src={fullstack}/><br/>
                        Execution of Full Stack Application
                    </Col>
                  
                </Row>
            </Container>
        </div>
    );
}

export {Tech};